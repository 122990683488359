// frontend/src/App.js

import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import Spinner from './components/Spinner';

function App() {
  const [wish, setWish] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const handleWishSubmit = async (e) => {
    e.preventDefault();

    if (!wish.trim()) return;

    setLoading(true);
    setResponse('');

    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/genie`, { wish });
      setResponse(res.data.response);
      setHistory([...history, { wish, response: res.data.response }]);
    } catch (error) {
      console.error('Error submitting wish:', error);
      setResponse('An error occurred while processing your wish.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>Chaotic Genie</h1>
      <p>Make a wish, but beware of unintended consequences!</p>
      <form onSubmit={handleWishSubmit}>
        <input
          type="text"
          value={wish}
          onChange={(e) => setWish(e.target.value)}
          placeholder="Enter your wish here..."
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Granting...' : 'Submit Wish'}
        </button>
      </form>
      {loading && <Spinner />}
      {response && (
        <div className="response">
          <h2>Genie Response:</h2>
          <p>{response}</p>
        </div>
      )}
      {history.length > 0 && (
        <div className="history">
          <h2>Wish History:</h2>
          <ul>
            {history.map((item, index) => (
              <li key={index}>
                <strong>Wish:</strong> {item.wish}
                <br />
                <strong>Response:</strong> {item.response}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
